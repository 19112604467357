.toolbar_options {
    float: right;
    margin-right: 20px;
}

.radio {
    background-color: lightgray;
    margin-right: 0px;
    padding: 4px;
    border: 1px solid black;
    cursor: pointer;
}

.radio.selected {
    background-color: peachpuff;
    font-weight: 600;
}

.radio:not(.selected):hover {
    background-color: burlywood;
}

.radio:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.radio:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.button {
    display: inline-block;
    text-align: center;
    background-color: lightgray;
    border: 1px solid black;
    cursor: pointer;
    padding: 3px;
    border-radius: 5px;
}

.button:hover {
    background-color: burlywood;
    transform: scale(1.05);
}

.big {
    font-size: 24px;
    padding: 15px;
    margin: 10px;
    width: 180px;
    border-radius: 10px;
}

.advanced {
    display: inline-block;
    margin-left: 20px;
}

.hidden {
    visibility: hidden;
}
