.image-container {
    position: relative;
    width: 97%;
    user-select: none;
    -webkit-user-select: none;
}

.image {
    max-height: 100%;
    width: 100%;
    border: 1px black solid;
}

.overlay-canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: calc(100% - 5px);
}
