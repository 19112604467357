.wordsearch .grid {
    width: 100%;
    height: calc(100vh - 450px);
}

.wordsearch .word-bank {
    width: 100%;
    height: 150px;
}

.wordsearch .input table td {
    text-align: center;
    font-family: monospace;
    font-size: 17px;
    height: 20px;
    width: 20px;
}

.wordsearch .output {
    height: calc(100vh - 150px);
    overflow: scroll;
    user-select: none;
}

.wordsearch .output .link {
    display: inline-flex;
    font-family: monospace;
    border: solid 3px transparent;
    border-radius: 0;
    margin: 2px;
    padding: 3px 5px;
    width: 180px;
}

.wordsearch .unused-letters {
    font-family: monospace;
    width: 400px;
    height: 100px;
}

.wordsearch input[type=button] {
    margin-right: 2px;
}