
.popup {
    position: absolute;
    z-index: 3;
    padding: 15px;
    background-color: white;
    border: 5px black solid;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.submit-section {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.grayed-out {
    opacity: .2;
}

.hide {
    display: none;
}

.small-button {
    min-width: 36px;
}

.center {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 800;
}

/* Individual popup features */

.advanced-settings-popup {
    width: 300px;
    height: 150px;
}

.export-popup {
    width: 500px;
    height: 250px;
}
