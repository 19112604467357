body {
    padding: 10px;
}

img[src="./wrench.png"] {
    width: 48px;
    height: 48px;
}

.title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px;
}

.inline {
    margin-right: 5px;
    display: inline;
}

.block {
    margin-bottom: 20px;
}

.link {
    color: #000;
    background-color: #f1f1f1;
    font-size: 18px;
    border-radius: 10%;
    cursor: pointer;
}

.link.selected {
    background-color: #cccccc;
}

.loading {
    border: 3px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 3px solid #555;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.input, .output {
    float: left;
    margin-right: 20px;
}

.input {
    width: calc(45% - 50px);
}

.output {
    width: calc(55% - 50px);
}
